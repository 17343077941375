<template>
    <v-row justify="center" >
      <v-dialog
        v-model="dialog"
        :width="!isMobileDevice ? '550px' : ''"
        :fullscreen="isMobileDevice"
        style="padding-bottom: 20px;overflow-y: hidden !important; overflow-x: hidden !important;"
      >
       <div ref="downloadPredictions">
        <v-card class="mx-auto pb-12" max-width="550"
                :dark="true" style="overflow-y: hidden !important;overflow-x: hidden !important;border-radius: 3px;border: #cdb15f solid 5px;background-image: url('img/home/arena_bg_vertical.webp');background-size: cover">
          <v-layout>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
       

          <!-- v-row :justify="'center'">
            <img
              src="/img/logos/superlive_x_mola_horizontal.webp"
              style="height: 45px; width: 237px;"
            />
          </v-row> -->

          <v-card-title
            class="justify-center mt-4"
          >
            <div class="text-center" :style="{
              fontFamily:'Furore',
              fontSize: isMobileDevice ? '2.5rem' : '3.5rem',
              lineHeight: isMobileDevice ? '2.5rem' : '3.5rem'
              }">
              <div style="color: #FFF">{{ lang[getLanguage].YOUR_PREDICTION_1 }}</div>
              <div style="color: #f8df8e">{{ lang[getLanguage].YOUR_PREDICTION_2 }}</div>
            </div>
          </v-card-title>
          <v-window>
            <v-window-item :value="1">
              <v-card-text>
                <div class="" v-if="getEventList.length > 0">
                    <div class="text-center text-uppercase px-16 grey--text" style="font-size: 14px; line-height: normal;">
                      <div class="text-h7 font-weight-bold mt-2 mb-1 text-center">{{ getEventList[0].name }}</div>
                      <span class="text-h7 font-weight-bold p-0 m-0" >{{ makeDate(getEventList[0].fights[0].start) }} {{ getEventList[0].venue.name + ', ' + getEventList[0].venue.city_name }}</span>
                    </div>
                    
                </div>
                
              </v-card-text>
            </v-window-item>
            <div id="canvasContainer">
              <v-row :justify="'center'" :class="isMobileDevice ? 'mx-8' : 'mx-10'">
                <v-col cols="6" md="6" class="px-0"
                  v-for="(item, index) in getUserPredictions" :key="index+ 'a'" :align="'center'">
                  <canvas  
                          :key="index" :ref="`myCanvas_${index}`" 
                          :id="`myCanvas_${index}`" height="200"
                          :style="{
                            width: isMobileDevice ? '100%' : '100%',
                            margin: '0 auto'
                          }"
                    >
                  </canvas>
                </v-col>
              </v-row>
            </div>
            <!-- <div id="canvasContainer">
              <div class="canvas-image" v-for="(item, index) in getUserPredictions" :key="index">
                <div style="ml-4">
                <v-list-item-avatar style="margin-left: 20px; width:90px;height:90px; border: 4px solid #404040; position:static">
                  <v-img
                    v-if="item.p_outcome !== 3"
                    :src="imageUrl(item.fighter_id)"
                    :id="`img_${index}`"
                    ></v-img>
                  
                  <v-img
                    v-if="item.p_outcome === 3" 
                    src="/img/icons/draw_prediction.png"
                    >
                  </v-img>
                </v-list-item-avatar>
                </div>
                <div class="canvas-body">
                  <div style="color: #cdb15f; font-size: 18px">FIGHTER {{index + 1}}</div>
                  <div>Winner: {{item.p_outcome_name}}</div>
                  <div>How: {{item.p_method_name}}</div>
                  <div>Rounds: {{item.p_rounds}}</div>
                </div>
              </div> -->
              <!-- <canvas v-for="(item, index) in getUserPredictions" :key="index" :ref="`myCanvas_${index}`" :id="`myCanvas_${index}`" height="80" style="border-radius: 50px; background: #172854; width: 300px; margin: 0 auto"></canvas> -->
            <!-- </div> -->
            <v-window-item >
              <div class="pa-4 text-center" ref="socialFooter">
                <div class="">
                  <h6 class="text-h6 font-weight-bold mb-1" style="color: #cdb15f">
                    {{ lang[getLanguage].COPY_PREDICTION }} <v-icon size="20" color="#cdb15f" @click="copyToClipBoard">mdi-content-copy</v-icon>
                  </h6>
                  </div>
                <h6 class="text-h6 font-weight-bold mb-2" style="color: #cdb15f">
                  {{ lang[getLanguage].SHARE_PREDICTION }}
                </h6>
                <div class="social-icon-container">
                  <div class="">
                    <v-icon size="40" color="#cdb15f" @click.stop="share()">mdi-share-variant-outline</v-icon>
                  </div>
                  <div class="">
                    <v-icon size="40" color="#cdb15f" @click.prevent="downloadUserPredictions">mdi-download-circle-outline</v-icon>
                  </div>
                </div>
              <div row gap="1" v-show="fallbackShare" justify-center>
                <div class="ma-4">
                  <p style="color: #cdb15f" class="font-weight-medium text-center" >{{  lang[getLanguage].NEWS_SHARE_VIA}}</p>
                </div>
                <ShareNetwork
                  network="facebook"
                  :url="'https://molafightclub.com/'"
                  title="Checkout my Predictions"
                  style="margin-right: 10px"
                >
                  <v-btn fab color="#404040" style="border-radius:10px" medium dark><v-icon size="40" color="#cdb15f">mdi-facebook</v-icon></v-btn>
                </ShareNetwork>
                <ShareNetwork
                    network="whatsapp"
                    :url="'https://molafightclub.com/'"
                    title="Checkout my Predictions"
                    style="margin-right: 10px"
                  >
                  <v-btn fab color="#404040" style="border-radius:10px" medium dark><v-icon size="40" color="#cdb15f">mdi-whatsapp</v-icon></v-btn>
                </ShareNetwork>
                <ShareNetwork
                    network="twitter"
                    url="'https://molafightclub.com/'"
                    title="Checkout my Predictions"
                    style="margin-right: 10px"
                  >
                  <v-btn fab color="#404040" style="border-radius:10px" medium dark><v-icon medium size="40" color="#cdb15f">mdi-twitter</v-icon></v-btn>
                </ShareNetwork>
                <ShareNetwork
                network="email"
                :url="'https://molafightclub.com/'"
                title="Checkout my Predictions"
                style="margin-right: 10px"
              >
                <v-btn fab color="#404040" style="border-radius:10px" medium dark><v-icon size="40" color="#cdb15f">mdi-at</v-icon></v-btn>
            </ShareNetwork>
              </div>
              </div>
            </v-window-item> 
          </v-window>
        </v-card>
        </div>

        <v-snackbar :timeout="5000" :bottom="'bottom'" v-model="snackbar">
          <v-icon :color="snackbarColor">{{ snackbarIcon }}</v-icon>
          <span class="ml-2" v-html="snackbarText"></span>
          <v-btn text color="blue" @click.native="snackbar = false" style="margin-left:25px">Close</v-btn>
        </v-snackbar>

      </v-dialog>
    </v-row>
  </template>
  <script>
  import dateformat from "dateformat"
  import html2canvas from 'html2canvas';
  import MolaMfpAPI from '../../clients/MolaMfpAPI';
  export default {
    props: {
      isOpen: Boolean,
      isMobileDevice: Boolean,
    },
    data(){
      return {
        snackbar: false,
        snackbarText: '',
        snackbarIcon: 'mdi-check-circle-outline',
        snackbarColor: 'green',
        fallbackShare: false,
        imgUrl: '',
        mediaShare: null,
      }
    },
    mounted() {
     this.drawCanvas();
    },
    computed: {
      getFighterList () {
        return this.$store.state.fb.fighterList
      },
      getEventList () {
        return this.$store.state.fb.eventList
      },
      dialog: {
        get() {
          return this.isOpen;
        },
        set(newValue) {
          this.$emit('change', newValue);
        },
      },
      getUser () {
        return this.$store.state.user
      },
      lang(){
        return this.$store.state.lang;
      },
      getLanguage(){
        return this.$store.state.current_language
      },
      getUserPredictions () {
        return this.$store.state.fb.userPredictionsArr
      },
    },
    methods: {
      async drawCanvas() {
    function drawShape(x, y, r, sides, ctx) {
        ctx.translate(x, y);

        for (let i = 0; i < sides; i++) {
            const rotation = ((Math.PI * 2) / sides) * i + 20.0275;

            if (i === 0) {
                ctx.moveTo(r * Math.cos(rotation), r * Math.sin(rotation));
            } else {
                ctx.lineTo(r * Math.cos(rotation), r * Math.sin(rotation));
            }
        }

        ctx.closePath();
        ctx.strokeStyle = "rgb(248,223,142)";
        ctx.lineWidth = 3;
        ctx.stroke();

        ctx.resetTransform();
    }

    let arr = this.getUserPredictions.sort((a, b) => {
        return (a.created ?? 0) - (b.created ?? 0);
    });

    for (let i = 0; i < arr.length; i++) {
        const c = document.getElementById(`myCanvas_${i}`);
        const container = document.getElementById('canvasContainer');
        const image = document.createElement('img');
        let url;
        if (arr[i].p_outcome !== 3) {
            const src = this.imageUrl(arr[i].fighter_id);
            url = await this.getUserBlobBase64(src);
        } else {
            url = "/img/icons/draw_prediction.png";
        }

        if (arr[i].p_outcome === 3) {
            image.src = url;
        } else {
            image.src = `data:image/png;base64, ${url}`;
        }
        image.style.display = 'none';
        if (container) {
            container.appendChild(image);
        }

        const ctx = c.getContext('2d');
        const canvasWidth = c.width;

        const centerX = canvasWidth / 2;

        drawShape(centerX, 100, 100, 8, ctx);

        const fightNum = i + 1;

        image.onload = () => {
            if (arr[i].p_outcome === 3) {
                ctx.drawImage(image, centerX - 45, 8, 90, 90);
            } else {
                ctx.drawImage(image, centerX - 75, 8, 150, 90);
            }
        };

        ctx.font = '600 1rem arial';
        ctx.fillStyle = '#cdb15f';
        ctx.textAlign = "center";
        ctx.fillText(`FIGHT ${fightNum}`, centerX, 120);

        ctx.fillStyle = '#fff';
        ctx.fillText(`${arr[i].p_outcome_name}`, centerX, 140);
        ctx.fillText(`${this.lang[this.getLanguage].BROADCAST_HOW_2} ${arr[i].p_method_name.split(' ')[0]}`, centerX, 160);
        ctx.fillText(`${this.lang[this.getLanguage].BROADCAST_WHICH_ROUND_2} ${arr[i].p_rounds}`, centerX, 180);
    }
},
      imageUrl(id){
          let url;
          for(let i = 0; i < this.getFighterList.length; i++) {
            if(this.getFighterList[i].id === id){
              url = this.getFighterList[i].c_photo.url;
              break;
            }
          }
          return url;
        },
        async getUserBlobBase64(url){
          const data = {
            "img_url": url
          }
          const response = await MolaMfpAPI.getBlobBase64(data)
          if(response.status == 200){
            return response.data.blobData;
          }
        },
        makeDate(date){
          return dateformat(new Date(date), 'dd mmm, yyyy')
        },
        async share() {
          const el= this.$refs.downloadPredictions;
          const options = {
                type: "dataURL",
                logging: true,
                useCORS: false,
                allowTaint: true,
                backgroundColor:'#404040',
            };
          const socialShareBtn = this.$refs.socialFooter;
          socialShareBtn.style.display = 'none';
          let item;
          html2canvas(el, options).then(canvas => {
            canvas.toBlob(async blob => {
              item = new File([blob], 'file.png', {
                type: 'image/png',
              });
              const shareData = {
                files: [item],
              }
              try {
                await navigator.share(shareData);
              } catch (err) {
                // console.log(err);
                if (err.message === 'Abort due to cancellation of share.') {
                  return
                }
                // this.mediaShare = item;
                // this.fallbackShare = !this.fallbackShare
                // socialShareBtn.style.display = 'block';
                this.snackbarText = `Fitur berbagi tidak didukung di browser ini, <br><span style='margin-left:31px'>silakan gunakan opsi unduh<span>`;
                this.snackbarColor = "orange";
                this.snackbarIcon = "mdi-message-alert-outline"
                this.snackbar = true;
                /* console.log(err);
                this.snackbarText = err;
                this.snackbarColor = "red";
                this.snackbarIcon = "mdi-message-alert-outline"
                this.snackbar = true;
                console.log(err)
                */
                // navigator.clipboard.write([shareData]);
              }
            }, 'image/png');
          });
          socialShareBtn.style.display = 'block'
        },
        async downloadUserPredictions() {
          const socialShareBtn = this.$refs.socialFooter;
          socialShareBtn.style.display = 'none';
          window.scrollTo(0, 0);
          const el = this.$refs.downloadPredictions;
          const options = {
            type: "dataURL",
            useCORS: false,
            allowTaint: true,
            logging: true,
            backgroundColor:'#404040',
            scale: 2
          };
          const printCanvas = await html2canvas(el, options);
          const link = document.createElement("a");
          link.setAttribute("download", `${this.getUser.name}_predictions.png`);
          link.setAttribute(
            "href",
            printCanvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
          );
          link.click();
          socialShareBtn.style.display = 'block';
    },
    copyToClipBoard(){
      console.log(this.user)
      const el= this.$refs.downloadPredictions;
      const options = {
            type: "dataURL",
            useCORS: false,
            allowTaint: true,
            backgroundColor:'#404040',
        };
      const socialShareBtn = this.$refs.socialFooter;
      socialShareBtn.style.display = 'none';
      html2canvas(el, options).then(canvas => {
        canvas.toBlob(blob => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]);
          this.snackbarText = this.lang[this.getLanguage].SUCCESS_COPY_IMAGE;
          this.snackbarColor = "green";
          this.snackbarIcon = "mdi-message-alert-outline"
          this.snackbar = true;
        }, 'image/png');
      });
    socialShareBtn.style.display = 'block';
    },
  }
  };
  </script>
  <style scoped>
    @font-face {
      font-family: 'Furore';
      font-style: normal;
      font-weight: normal;
      src: local('Furore'), url('../../assets/Furore.woff') format('woff');
    }
    .duelTitle {
        font-family: 'Righteous', cursive;
        font-size:36px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
        transform: none !important;
    }

    .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
        border-radius: 25px;
    }

    .text-yellow-mola {
        color: #cdb15f;
    }
    #canvas {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 200px;
    }
    .social-icon-container {
      display: flex;;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    #canvasContainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .canvas-image {
      display: grid;
      grid-template-columns: 1fr 2fr;
      background-color: #172854;
      width: 330px;
      border-radius: 60px;
      justify-content: center;
      align-items: center;
      height: 120px;
    }
    .canvas-body {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  </style>
  